export const DATE_FORMATS = {
  Day: "HH:mm",
  Week: "D MMM",
  Month: "D MMM",
  Quarter: "MMM",
  Year: "MMM 'YY",
  Max: "MMM 'YY"
};

export const POPUP_DATE_FORMATS = {
  Day: "YYYY-MM-DD",
  Week: "YYYY-MM-DD",
  Month: "YYYY-MM-DD",
  Quarter: "YYYY-MM-DD",
  Year: "YYYY-MM-DD",
  Max: "YYYY-MM-DD"
};

export const PERFORMANCE_DATE_FORMATS = {
  Day: "HH:mm",
  Week: "D MMM",
  Month: "D MMM",
  Quarter: "MMM 'YY",
  Year: "MMM 'YY",
  Max: "MMM 'YY"
};
