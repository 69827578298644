import { CONST_VALUES } from "../constants/constants";
import { STORAGE_OPTIONS_VALUE } from "./commonFuntions";

export function getDeepLink() {
  const url = window?.location?.href;
  let deepLinkPart = "";
  let parts = null;
  if (url != null && url != "") {
    parts = url?.split("#");
    deepLinkPart = parts[1];
  }

  return deepLinkPart;
}

export function getRelayState(param) {
  if (
    param.split("/")[1] === "asset-details" ||
    param.split("/")[1] === "inventory" ||
    param.split("/")[1] === "permissions" ||
    param.split("/")[1] === "asset-report" ||
    param.split("/")[1] === "alerts" ||
    param.split("/")[1] === "asset-info" ||
    param.split("/")[1] === "site-info" ||
    param.split("/")[1] === "credentials" ||
    param.split("/")[1] === "customer-demo" ||
    param.split("/")[1] === "partner-demo"
  ) {
    return (
      "?RelayState=" +
      "deepLink=" +
      encodeURIComponent(decodeURIComponent(param))
    );
  } else {
    return "";
  }
}

function validateIPaddress(ipaddress) {
  if (
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
      ipaddress
    )
  )
    return true;
  return false;
}

export function escapeInnerOuterHTML(dirtyHtml) {
  // Create a temporary element
  const temp = document.createElement("div");
  temp.innerHTML = dirtyHtml;

  // Remove any script tags
  const scripts = temp.getElementsByTagName("script");
  for (let i = scripts.length - 1; i >= 0; i--) {
    scripts[i].parentNode.removeChild(scripts[i]);
  }

  // Remove any event attributes
  const elements = temp.querySelectorAll("*");
  elements.forEach(element => {
    const attributes = element.attributes;
    for (let i = attributes.length - 1; i >= 0; i--) {
      const attributeName = attributes[i].name;
      if (attributeName.startsWith("on")) {
        element.removeAttribute(attributeName);
      }
    }
  });

  // Return the sanitized HTML
  return temp.innerHTML;
}

export function validateUrlForSec(url_details) {
  var parser = document?.createElement("a");
  parser.href = url_details;

  if (url_details?.includes("https")) {
    let hostname = parser?.hostname;
    let onlydomain = hostname?.split(".");
    onlydomain =
      onlydomain[onlydomain?.length - 2] +
      "." +
      onlydomain[onlydomain?.length - 1];
    if (
      CONST_VALUES?.valid_hostnames?.includes(hostname) ||
      CONST_VALUES?.valid_hostnames?.includes(onlydomain) ||
      validateIPaddress(hostname)
    ) {
      if (
        CONST_VALUES?.invalid_chars?.find(a => url_details?.includes(a)) ===
        undefined
      ) {
        return url_details;
      } else {
        return "/";
      }
    } else {
      return "/";
    }
  } else if (url_details?.startsWith("/")) {
    return url_details;
  } else {
    return "";
  }
}

// Temp fix to handle download csv asked by Ganesh

export function validateUrlForExcel(url_details) {
  var parser = document?.createElement("a");
  parser.href = url_details;

  let hostname = parser?.hostname;
  let onlydomain = hostname?.split(".");
  onlydomain =
    onlydomain[onlydomain?.length - 2] +
    "." +
    onlydomain[onlydomain?.length - 1];
  if (
    CONST_VALUES.valid_hostnames.includes(hostname) ||
    CONST_VALUES.valid_hostnames.includes(onlydomain) ||
    validateIPaddress(hostname)
  ) {
    if (
      CONST_VALUES.invalid_chars?.find(a => url_details?.includes(a)) ===
      undefined
    ) {
      return url_details;
    } else {
      return "/";
    }
  } else {
    return "/";
  }
}

export function checkValidUrl() {
  try {
    // intentionally kept empty
  } catch (e) {
    window.location.href = "/";
  }
}

export function getLocalStorageValue(data) {
  switch (data) {
    case "zero":
      return 0;
    case "one":
      return 1;
    default:
      break;
  }
}

export function validateLocalStorageValue(data) {
  let values = [
    "0",
    "1",
    "overview",
    "block",
    "object",
    "file",
    "sustainability",
    "security",
    "null"
  ];
  if (values.indexOf(String(data)) > -1) {
    return data;
  } else {
    return -1;
  }
}

export const transformDataCustomSelect = data => {
  return data.map(row => ({
    id: row?.id,
    value: row?.label,
    label: row?.label
  }));
};

export const transformReactSelectData = (data, typeId) => {
  return data.map(row => ({
    value: row?.salesforce_site_id,
    label: row?.salesforce_site_id,
    id: row?.salesforce_site_id,
    siteWithAlias: row?.site_name
      ? `${row?.salesforce_site_id}` + ` (${row?.site_name})`
      : row?.salesforce_site_id,
    type: typeId === 1 ? "block" : typeId === 2 ? "object" : "file"
  }));
};

export const debounceFun = (func, delay) => {
  let debounceTimer;
  return function() {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export const displayValue = value => {
  if (value === null) {
    return "null";
  } else if (value === "") {
    return '""';
  } else {
    return value?.toString();
  }
};

const valueIsObject = obj => {
  return !Array.isArray(obj) && typeof obj === "object" && obj !== null;
};

export const filterObjectByKey = (query, obj) => {
  const q = query?.toLowerCase?.().trim();

  if (valueIsObject(obj)) {
    let buffer = {};
    for (let key in obj) {
      if (key?.toLowerCase().includes(q) || valueIsObject(obj[key])) {
        if (valueIsObject(obj[key])) {
          let filteredValueObject = filterObjectByKey(q, obj[key]);
          if (Object.keys(filteredValueObject).length !== 0) {
            buffer[key] = filteredValueObject;
          }
        }

        if (key.toLowerCase().includes(q)) {
          buffer[key] = obj[key];
        }
      }
    }
    return buffer;
  } else if (Array.isArray(obj)) {
    return obj.map(n => filterObjectByKey(q, n));
  }
};

export const itemLabel = (element, i, rootElement, parent) => {
  try {
    if (Array.isArray(element)) {
      return `${parent || "array"} [${element?.length}]`;
    } else if (
      typeof element === "object" &&
      !Array.isArray(element) &&
      !rootElement
    ) {
      return `${parent || "object"} {${element &&
        Object.keys(element)?.length}}`;
    } else {
      return `${i} {${element && Object.keys(element)?.length}}`;
    }
  } catch (e) {
    console.error(e);
  }
};
export const convertToLowerAndTrim = value => {
  return value?.toLowerCase()?.trim();
};

export const getProductCategoryId = storageOption => {
  return STORAGE_OPTIONS_VALUE[storageOption?.toLowerCase()] || "";
  // storageOption === "block"
  //   ? "1"
  //   : storageOption === "object"
  //   ? "2"
  //   : storageOption === "file"
  //   ? "3"
  //   : "";
};

export const removeDuplicates = (array, key) => {
  return array.filter(
    (item, index, self) => index === self.findIndex(t => t[key] === item[key])
  );
};
