import React from "react";

export default props => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0026 21.3333C8.89294 21.3333 6.83065 20.7077 5.07653 19.5357C3.3224 18.3636 1.95523 16.6977 1.14789 14.7486C0.340559 12.7995 0.129324 10.6548 0.540899 8.58571C0.952475 6.51658 1.96838 4.61596 3.46014 3.1242C4.9519 1.63244 6.85252 0.616538 8.92165 0.204962C10.9908 -0.206614 13.1355 0.00462166 15.0846 0.811956C17.0336 1.61929 18.6995 2.98646 19.8716 4.74059C21.0437 6.49471 21.6693 8.557 21.6693 10.6667C21.661 13.4931 20.5345 16.2014 18.5359 18.2C16.5373 20.1986 13.829 21.3251 11.0026 21.3333ZM11.0026 1.33334C9.15665 1.33334 7.35215 1.88073 5.81729 2.90629C4.28243 3.93185 3.08615 5.38952 2.37973 7.09496C1.67332 8.8004 1.48848 10.677 1.84861 12.4875C2.20874 14.298 3.09766 15.961 4.40295 17.2663C5.70824 18.5716 7.37128 19.4605 9.18177 19.8207C10.9923 20.1808 12.8689 19.996 14.5743 19.2895C16.2798 18.5831 17.7374 17.3869 18.763 15.852C19.7886 14.3171 20.3359 12.5126 20.3359 10.6667C20.3287 8.19353 19.3431 5.82375 17.5943 4.07497C15.8455 2.3262 13.4757 1.34055 11.0026 1.33334Z"
      fill="#414141"
    />
    <path
      d="M11.6693 15.3333H10.3359V16.6667H11.6693V15.3333Z"
      fill="#414141"
    />
    <path
      d="M15.0026 8.66667C15.0026 7.6058 14.5812 6.58836 13.831 5.83822C13.0809 5.08807 12.0635 4.66667 11.0026 4.66667C9.94174 4.66667 8.92434 5.08807 8.17419 5.83822C7.42405 6.58836 7.0026 7.6058 7.0026 8.66667H8.33594C8.33594 8.13925 8.49234 7.62367 8.78536 7.18514C9.07838 6.74661 9.49483 6.4048 9.9821 6.20296C10.4694 6.00113 11.0055 5.94838 11.5228 6.05127C12.0401 6.15416 12.5153 6.40815 12.8882 6.78109C13.2612 7.15403 13.5151 7.62912 13.618 8.1464C13.7209 8.66368 13.6681 9.1999 13.4663 9.68717C13.2644 10.1744 12.9227 10.5909 12.4841 10.884C12.0456 11.177 11.53 11.3333 11.0026 11.3333H10.3359V14H11.6693V12.6C12.5986 12.4408 13.4421 11.9592 14.0517 11.2399C14.6613 10.5206 14.998 9.60952 15.0026 8.66667Z"
      fill="#414141"
    />
  </svg>
);
