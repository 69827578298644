import React from "react";
import { SvgIcon } from '@mui/material';

export default props => (
  <SvgIcon {...props} width="32" height="32" viewBox="0 0 32 32">
    <defs>
      <clipPath id="clip-path">
        <rect width="32" height="32" fill="none" />
      </clipPath>
    </defs>
    <g id="Component" clipPath="url(#clip-path)">
      <rect
        id="Rectangle_1214"
        data-name="Rectangle 1214"
        width="32"
        height="32"
        fill="none"
      />
      <path
        id="Path_977"
        data-name="Path 977"
        d="M6,0V6H0Z"
        transform="translate(16 19.243) rotate(-135)"
        fill="#dedede"
      />
      <path
        id="Path_978"
        data-name="Path 978"
        d="M6,0V6H0Z"
        transform="translate(16 12.758) rotate(45)"
        fill="#dedede"
      />
    </g>
  </SvgIcon>
);
