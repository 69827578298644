import React from "react";

export default props => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9205 7.36052C18.6498 5.45261 17.6995 3.70652 16.2443 2.44323C14.7891 1.17995 12.9268 0.484375 10.9998 0.484375C9.07278 0.484375 7.21051 1.17995 5.75533 2.44323C4.30014 3.70652 3.34985 5.45261 3.07914 7.36052C2.17511 7.65767 1.40662 8.26738 0.911692 9.08016C0.416764 9.89295 0.227821 10.8556 0.378794 11.7951C0.529766 12.7347 1.01076 13.5896 1.73541 14.2065C2.46005 14.8233 3.38086 15.1616 4.33247 15.1605V8.04052L4.39914 7.54853C4.62558 5.9594 5.41778 4.50533 6.63023 3.45339C7.84268 2.40146 9.39396 1.82231 10.9991 1.82231C12.6043 1.82231 14.1556 2.40146 15.3681 3.45339C16.5805 4.50533 17.3727 5.9594 17.5991 7.54853L17.6658 8.01519V12.4939C17.6622 13.8494 17.245 15.1716 16.4699 16.2838C15.6949 17.3959 14.5989 18.2451 13.3285 18.7179C13.0694 18.3218 12.6788 18.0299 12.2255 17.8936C11.7723 17.7573 11.2855 17.7854 10.8509 17.9729C10.4163 18.1604 10.0619 18.4953 9.85006 18.9185C9.63824 19.3418 9.58263 19.8262 9.69303 20.2865C9.80344 20.7467 10.0728 21.1532 10.4535 21.4343C10.8343 21.7154 11.3021 21.8531 11.7745 21.8231C12.2468 21.793 12.6934 21.5972 13.0355 21.2701C13.3776 20.9431 13.5933 20.5057 13.6445 20.0352C14.7911 19.6321 15.831 18.9736 16.6857 18.1094C17.5404 17.2453 18.1874 16.1982 18.5778 15.0472C19.4272 14.85 20.1888 14.3804 20.7463 13.7099C21.3037 13.0394 21.6265 12.205 21.6654 11.3339C21.7042 10.4628 21.4569 9.60287 20.9613 8.88544C20.4657 8.16802 19.749 7.63246 18.9205 7.36052ZM2.99914 13.4552C2.59516 13.226 2.25916 12.8938 2.02541 12.4925C1.79165 12.0911 1.6685 11.635 1.6685 11.1705C1.6685 10.7061 1.79165 10.2499 2.02541 9.84857C2.25916 9.44722 2.59516 9.11503 2.99914 8.88586V13.4552ZM18.9991 13.4552V8.88186C19.4039 9.11088 19.7406 9.44324 19.9749 9.84499C20.2092 10.2467 20.3326 10.7035 20.3326 11.1685C20.3326 11.6336 20.2092 12.0903 19.9749 12.4921C19.7406 12.8938 19.4039 13.2262 18.9991 13.4552Z"
      fill="#414141"
    />
  </svg>
);
