import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Chart, registerables } from "chart.js";
import { loadHotjar } from "./utils/hotjarConfig";
import { getEnvironment, getGaIdEnv } from "./utils/gaaperture";
import { COMMON_CONSTANTS } from "./constants/constants";
import "./stylesheets/index.scss";
import infoIcon from "./resources/images/info-icon.svg";
// REACT_APP_API_URL is baked in at buildtime, not runtime
// Create CSP dynamically so we can include the REACT_APP_API_URL
const head = document.getElementsByTagName("head")[0];
const csp = document.createElement("meta");
csp.setAttribute("http-equiv", "Content-Security-Policy");
head.appendChild(csp);

head.appendChild(csp);
let environmentName = getEnvironment();
let [code, hotjarSiteId] = getGaIdEnv(environmentName);

window.addEventListener("load", event => {
  loadHotjar();
});

window.dataLayer = window.dataLayer || [];
export function gtag() {
  window.dataLayer.push(arguments);
}

gtag("js", new Date());

gtag("config", code);

const checkIfDataNotPresent = data => {
  return data.every(element => element === null);
};

registerables &&
  Chart.register(...(registerables || []), {
    id: "basicChart",
    afterDraw: function(chart) {
      if (
        chart.data.datasets.every(item => item.data.length === 0) ||
        chart.data.datasets.every(item => checkIfDataNotPresent(item.data))
      ) {
        const ctx = chart.ctx;
        const chArea = chart.chartArea;
        const x_center = (chArea.right + chArea.left) / 2;
        const y_center = (chArea.bottom + chArea.top) / 2;
        let img = new Image();
        img.src = infoIcon;
        // Estimated/approximate half width and height of the text to be visible, to get the rectangle around it.
        // const textHalfWidth = 130;
        // const textHalfHeight = 20;
        // ctx.save();
        // ctx.textAlign = "center";
        // ctx.textBaseline = "middle";
        // ctx.font = "600 18px 'Helvetica Neue', sans-serif";
        // ctx.fillStyle = "rgb(158, 158, 158)";
        // ctx.fillRect(
        //   x_center - textHalfWidth,
        //   y_center - textHalfHeight,
        //   textHalfWidth * 2,
        //   textHalfHeight * 2
        // );
        // ctx.fillStyle = "rgb(255, 255, 255)";
        if (chart.data.datasets[0] && chart.data.datasets[0].customError) {
          if (chart.data.datasets[0].customError !== "No Legends Selected") {
            const textHalfWidth = 80;
            const textHalfHeight = 20;
            ctx.save();
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillStyle = "rgb(251, 252, 252)";
            ctx.fillRect(
              x_center - textHalfWidth,
              y_center - textHalfHeight,
              textHalfWidth * 2,
              textHalfHeight * 2
            );
            ctx.fillStyle = "#414141";
            ctx.font = "16px 'Helvetica Neue', sans-serif";
            if (chart.data.datasets[0].customError === "No Favorite Assets") {
              // img.onload = () => {
              const iconSize = 32; // Set the desired icon size
              const iconX = x_center - 48; // Adjust position as needed
              const iconY = y_center - iconSize / 2; // Adjust position as needed
              ctx.drawImage(img, iconX, iconY - 2, iconSize, iconSize);
              ctx.font = "400 16px 'Helvetica Neue', sans-serif";
              ctx.fillText("No favorites", x_center + 24, y_center);
            } else {
              ctx.fillText(
                chart.data.datasets[0].customError,
                x_center,
                y_center
              );
            }
          }
        } else {
          const textHalfWidth = 80;
          const textHalfHeight = 20;
          ctx.save();
          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.font = "16px 'Helvetica Neue', sans-serif";
          ctx.fillStyle = "rgb(251, 252, 252)";
          ctx.fillRect(
            x_center - textHalfWidth,
            y_center - textHalfHeight,
            textHalfWidth * 2,
            textHalfHeight * 2
          );
          ctx.fillStyle = "#414141";
          // img.onload = () => {
          const iconSize = 32; // Set the desired icon size
          const iconX = x_center - 82; // Adjust position as needed
          const iconY = y_center - iconSize / 2; // Adjust position as needed
          ctx.drawImage(img, iconX, iconY - 2, iconSize, iconSize);
          ctx.font = "400 16px 'Helvetica Neue', sans-serif";
          ctx.fillText(
            COMMON_CONSTANTS.DATA_NOT_AVAILABLE_MSG,
            x_center + 14,
            y_center
          );
        }
        ctx.restore();
      }
    }
  });

document.addEventListener("DOMContentLoaded", function() {
  ReactDOM.render(<App />, document.getElementById("root"));
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
