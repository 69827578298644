import React from "react";

export default props => (
  <svg
    width="32"
    height="20"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9974 3.16602C18.6345 3.16602 21.2123 3.948 23.405 5.41309C25.5977 6.87817 27.3066 8.96056 28.3158 11.3969C29.325 13.8333 29.589 16.5141 29.0745 19.1006C28.5601 21.687 27.2902 24.0627 25.4255 25.9274C23.5608 27.7921 21.185 29.062 18.5986 29.5765C16.0122 30.091 13.3313 29.8269 10.895 28.8177C8.4586 27.8086 6.37622 26.0996 4.91113 23.907C3.44605 21.7143 2.66406 19.1364 2.66406 16.4993C2.66406 12.9631 4.06882 9.57174 6.56931 7.07126C9.06979 4.57077 12.4612 3.16602 15.9974 3.16602Z"
      fill="#478B1A"
    />
    <path
      d="M12.7 22.8189L8.39062 18.5096L10.276 16.6242L12.6333 18.9816L21.0253 9.99023L22.9746 11.8096L12.7 22.8189Z"
      fill="#FBFCFC"
    />
  </svg>
);
