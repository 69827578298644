import React from "react";

export default props => (
  <svg
    width="32"
    height="20"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9974 3.5C18.6345 3.5 21.2123 4.28199 23.405 5.74707C25.5977 7.21216 27.3066 9.29454 28.3158 11.7309C29.325 14.1672 29.589 16.8481 29.0745 19.4345C28.5601 22.021 27.2902 24.3967 25.4255 26.2614C23.5608 28.1261 21.185 29.396 18.5986 29.9105C16.0122 30.4249 13.3313 30.1609 10.895 29.1517C8.4586 28.1426 6.37622 26.4336 4.91113 24.2409C3.44605 22.0483 2.66406 19.4704 2.66406 16.8333C2.66406 13.2971 4.06882 9.90573 6.56931 7.40524C9.06979 4.90476 12.4612 3.5 15.9974 3.5Z"
      fill="#F9C846"
    />
    <path d="M21.3307 15.5H10.6641V18.1667H21.3307V15.5Z" fill="#414141" />
  </svg>
);
