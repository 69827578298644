import React from "react";

export default props => (
  <svg
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.6016 24C14.4806 23.9976 12.4472 23.154 10.9474 21.6542C9.44761 20.1544 8.60394 18.121 8.60156 16C8.60394 13.879 9.44761 11.8456 10.9474 10.3458C12.4472 8.846 14.4806 8.00238 16.6016 8C18.7226 8.00238 20.756 8.846 22.2557 10.3458C23.7555 11.8456 24.5992 13.879 24.6016 16C24.5992 18.121 23.7555 20.1544 22.2557 21.6542C20.756 23.154 18.7226 23.9976 16.6016 24Z"
      fill="#ED4747"
    />
    <path d="M17.1016 12.002H16.1016V18.002H17.1016V12.002Z" fill="#FBFCFC" />
    <path d="M17.1016 19.002H16.1016V20.002H17.1016V19.002Z" fill="#FBFCFC" />
  </svg>
);
